import React from 'react'

function App() {
    return (
        <div className="App">
            <div className="flex justify-center items-center w-screen h-screen">
                <div className="filgo">
                    <span className='f' style={{ color: '#e83030' }}>F</span>
                    <span className='i' style={{ color: '#8cd147' }}>I</span>
                    <span className='l' style={{ color: '#8030e8' }}>L</span>
                    <span className='g' style={{ color: '#30a5e8' }}>G</span>
                    <span className='o' style={{ color: '#eeb72b' }}>O</span>
                </div>
            </div>
        </div>
    );
}

export default App;
